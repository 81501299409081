import { createStore } from "vuex"
import auth, { AuthState } from "./modules/auth"
import app, { AppState } from "./modules/app"
import company, { CompanyState } from "./modules/company"
import object, { ObjectState } from "./modules/object"
import interested, { InterestedState } from "./modules/interested"
import user, { UserState } from "./modules/user"
import publicUser, { PublicUserState } from "./modules/publicUser"
import customer, { CustomerState } from "./modules/customer"


export interface StoreState {
    auth?: AuthState
    app?: AppState
    company?: CompanyState
    object?: ObjectState
    interested?: InterestedState
    publicUser?: PublicUserState
    user?: UserState
    customer?: CustomerState

    isLoading: boolean
}

export default createStore<StoreState>({
    state: {
        isLoading: false
    },
    getters: {
    },
    mutations: {
    },
    actions: {
    },
    modules: {
        auth,
        app,
        company,
        object,
        interested,
        publicUser,
        user,
        customer
    }
})