import { Module } from "vuex"

export interface AuthState {
    isSignedIn: boolean
    isAuthenticating: boolean,
    accessToken: string,
    profile: {
        sub: string
        firstName: string
        lastName: string
        email: string
        gender: "male" | "female"
        company: string | null
        picture: string | null
        phone: string | null
    } | null
}

const module: Module<AuthState, unknown> = {
    state: {
        isSignedIn: false,
        isAuthenticating: true,
        accessToken: "",
        profile: null
    },
    mutations: {
        setAuthClaims(state, { user, claims }) {
            if (claims) {
                state.accessToken = user.accessToken
                state.profile = {
                    sub: user.uid,
                    firstName: claims.firstName as string,
                    lastName: claims.lastName as string,
                    email: claims.email as string,
                    gender: claims.gender as "male" | "female",
                    phone: claims.phone as string | null,
                    picture: claims.picture as string | null,
                    company: claims.company as string | null
                }
            } else {
                state.accessToken = ""
                state.profile = null
            }
        }
    }
}
export default module