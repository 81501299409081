
import { defineComponent } from "vue"
import IconItem from "@/components/theme/icon-item.vue"
import { mdiAccountOutline } from "@mdi/js"

export default defineComponent({
    props: {
        src: {
            type: String,
            required: false
        }
    },
    setup() {
        return {
            mdiAccountOutline
        }
    },
    components: {
        IconItem
    }
})
