import { User } from "@/classes/User"
import { firestore } from "@/firebase"
import { collection, doc, onSnapshot, Unsubscribe } from "firebase/firestore"
import store from ".."

import { Module } from "vuex"

export interface UserState {
    user: User | null,
    firestoreListener: {
        user: Unsubscribe | null
    }
}

const module: Module<UserState, unknown> = {
    state: {
        user: null,
        firestoreListener: {
            user: null
        }
    },
    actions: {
        loadUser({ state }) {
            return new Promise(async (resolve, reject) => {
                if (state.firestoreListener.user) {
                    resolve(state.user)
                    return
                }

                const authid = store.state.auth!.profile!.sub
                const ref = doc(collection(firestore, "user"), authid)
                state.firestoreListener.user = onSnapshot(ref, (snapshot) => {
                    const user = new User(snapshot.id)
                    if(snapshot.exists()){
                        user.fromData(snapshot.data()!)
                        state.user = user
                        resolve(state.user)
                    }else{
                        reject()
                    }
                })
            })
        }
    }
}
export default module