import { collection, doc, Timestamp } from "firebase/firestore"
import { Company } from "./Company"
import { FirestoreClass } from "./FirestoreClass"
import { Interested } from "./Interested"
import { ItemObject } from "./ItemObject"


export class Address {
    country: string = ""
    street: string = ""
    number: string = ""
    postcode: string = ""
    location: string = ""


    constructor(data: Record<string, unknown> | null = null) {
        if (data) {
            this.country = data.country as string || ""
            this.street = data.street as string || ""
            this.number = data.number as string || ""
            this.postcode = data.postcode as string || ""
            this.location = data.location as string || ""
           
        }
    }
    toData() {
        return {
            country: this.country,
            street: this.street,
            number: this.number,
            postcode: this.postcode,
            location: this.location        }
    }
}

export class ObjectEstateProvider {
    title: string = ""
    FirstName: string = ""
    LastName: string = ""
    Street: string = ""
    StreetNumber: string = ""
    Country: string = ""
    Postcode: string = ""
    Location: string = ""
    Feeder: string = ""
    Commission: number | string = ""
    CommissionType: string = ""

    constructor(data: Record<string, unknown> | null = null) {
        if (data) {
            this.title = data.title as string
            this.FirstName = data.FirstName as string
            this.LastName = data.LastName as string
            this.Street = data.Street as string
            this.StreetNumber = data.StreetNumber as string
            this.Country = data.Country as string
            this.Postcode = data.Postcode as string
            this.Location = data.Location as string
            this.Feeder = data.Feeder as string
            this.Commission = data.Commission as number | string
            this.CommissionType = data.CommissionType as string

        }
    }

    toData() {
        return {
            title: this.title,
            Country: this.Country,
            Postcode: this.Postcode,
            Location: this.Location,
            Feeder: this.Feeder,
            Commission: this.Commission,
            CommissionType: this.CommissionType,
            FirstName: this.FirstName,
            LastName: this.LastName,
            Street: this.Street,
            StreetNumber: this.StreetNumber
        }
    }
}

export class ObjectEstateCost {
    contractType: string = ""
    purchasePrice: number | string = ""
    purchasePriceType: string = ""
    purchaseCommision: number | string = ""
    purchaseCommisionType: string = ""
    houseMoney: number | string = ""
    houseMoneyType: string = ""
    commissionNotice: string = ""

    constructor(data: Record<string, unknown> | null = null) {
        if (data) {
            this.contractType = data.contractType as string
            this.purchasePrice = data.purchasePrice as number
            this.purchasePriceType = data.purchasePriceType as string
            this.purchaseCommision = data.purchaseCommision as number
            this.purchaseCommisionType = data.purchaseCommisionType as string
            this.houseMoney = data.houseMoney as number
            this.houseMoneyType = data.houseMoneyType as string
            this.commissionNotice = data.commissionNotice as string
        }
    }

    toData() {
        return {
            contractType: this.contractType,
            purchasePrice: this.purchasePrice,
            purchasePriceType: this.purchasePriceType,
            purchaseCommision: this.purchaseCommision,
            purchaseCommisionType: this.purchaseCommisionType,
            houseMoney: this.houseMoney,
            houseMoneyType: this.houseMoneyType,
            commissionNotice: this.commissionNotice
        }
    }
}

export class ObjectEstateUsage {
    usageType: string = ""
    livingSpaceType: string = ""
    livingSpace: number | string = ""
    landSpaceType: string = ""
    landSpace: number | string = ""
    totalRooms: number | string = ""

    constructor(data: Record<string, unknown> | null = null) {
        if (data) {
            this.usageType = data.usageType as string
            this.livingSpace = data.livingSpace as number
            this.livingSpaceType = data.livingSpaceType as string
            this.landSpaceType = data.landSpaceType as string
            this.landSpace = data.landSpace as number
            this.totalRooms = data.totalRooms as number
        }
    }

    toData() {
        return {
            usageType: this.usageType,
            livingSpaceType: this.livingSpaceType,
            livingSpace: this.livingSpace,
            landSpaceType: this.landSpaceType,
            landSpace: this.landSpace,
            totalRooms: this.totalRooms
        }
    }
}

export class ObjectEstateDetails {
    objectCurrentUsageType: string = ""
    objectCurrentUsageNotice: string = ""
    objectAvailableFrom: string = ""
    objectAvailableFromNotice: string = ""
    objectConstructionCompletionType: string = ""
    objectConstructionCompletionNotice: string = ""
    objectConstructionCompletionMonth: string = ""
    objectConstructionCompletionYear: string | number = ""
    objectLastModernisation: string = ""
    objectLastModernisationNotice: string = ""
    objectConditionType: string = ""
    objectConditionTypeNotice: string = ""
    objectWindowType: string = ""
    objectWindowTypeNotice: string = ""
    objectRoofType: string = ""
    objectRoofTypeNotice: string = ""
    objectRoofShapeType: string = ""
    objectRoofShapeTypeNotice: string = ""
    objectRoofCoverType: string = ""
    objectRoofCoverTypeNotice: string = ""
    objectDesignType: string = ""
    objectDesignTypeNotice: string = ""
    objectDevelopmentType: string = ""
    objectDevelopmentTypeNotice: string = ""
    objectBasementType: string = ""
    objectBasementTypeNotice: string = ""
    objectStories: string | number = ""
    objectStoriesNotice: string = ""

    objectFlooringFloor: boolean = false
    objectFlooringPVC: boolean = false
    objectFlooringCarpet: boolean = false
    objectFlooringFlow: boolean = false
    objectFlooringLaminate: boolean = false
    objectFlooringParquet: boolean = false
    objectFlooringStone: boolean = false
    objectFlooringScreed: boolean = false
    objectFlooringDoublefloor: boolean = false
    objectFlooringConcrete: boolean = false
    objectFlooringEpoxyResinFloor: boolean = false

    objectFlooringNotice: string = ""

    objectHeatingOven: boolean = false
    objectHeatingFloat: boolean = false
    objectHeatingCentral: boolean = false
    objectHeatingPump: boolean = false
    objectHeatingPowerPlant: boolean = false

    objectHeatingNotice: string = ""

    objectInternetSpeed: string | number= ""
    objectGardenType: string = ""
    objectGardenTypeNotice: string = ""

    constructor(data: Record<string, unknown> | null = null) {
        if (data) {
            this.objectCurrentUsageType = data.objectCurrentUsageType as string
            this.objectCurrentUsageNotice= data.objectCurrentUsageNotice as string
            this.objectAvailableFrom = data.objectAvailableFrom as string
            this.objectAvailableFromNotice = data.objectAvailableFromNotice as string
            this.objectConstructionCompletionType= data.objectConstructionCompletionType as string
            this.objectConstructionCompletionNotice= data.objectConstructionCompletionNotice as string
            this.objectConstructionCompletionMonth= data.objectConstructionCompletionMonth as string
            this.objectConstructionCompletionYear= data.objectConstructionCompletionYear as number
            this.objectLastModernisation= data.objectLastModernisation as string
            this.objectLastModernisationNotice= data.objectLastModernisationNotice as string
            this.objectConditionType= data.objectConditionType as string
            this.objectConditionTypeNotice= data.objectConditionTypeNotice as string
            this.objectWindowType= data.objectWindowType as string
            this.objectWindowTypeNotice= data.objectWindowTypeNotice as string
            this.objectRoofType= data.objectRoofType as string
            this.objectRoofTypeNotice= data.objectRoofTypeNotice as string
            this.objectRoofShapeType= data.objectRoofShapeType as string
            this.objectRoofShapeTypeNotice= data.objectRoofShapeTypeNotice as string
            this.objectRoofCoverType= data.objectRoofCoverType as string
            this.objectRoofCoverTypeNotice= data.objectRoofCoverTypeNotice as string
            this.objectDesignType= data.objectDesignType as string
            this.objectDesignTypeNotice= data.objectDesignTypeNotice as string
            this.objectDevelopmentType= data.objectDevelopmentType as string
            this.objectDevelopmentTypeNotice= data.objectDevelopmentTypeNotice as string
            this.objectBasementType= data.objectBasementType as string
            this.objectBasementTypeNotice= data.objectBasementTypeNotice as string
            this.objectStories= data.objectStories as number
            this.objectStoriesNotice= data.objectStoriesNotice as string

            this.objectFlooringFloor = data.objectFlooringFloor as boolean
            this.objectFlooringPVC = data.objectFlooringPVC as boolean
            this.objectFlooringCarpet = data.objectFlooringCarpet as boolean
            this.objectFlooringFlow = data.objectFlooringFlow as boolean
            this.objectFlooringLaminate = data.objectFlooringLaminate as boolean
            this.objectFlooringParquet = data.objectFlooringParquet as boolean
            this.objectFlooringStone = data.objectFlooringStone as boolean
            this.objectFlooringScreed = data.objectFlooringScreed as boolean
            this.objectFlooringDoublefloor = data.objectFlooringDoublefloor as boolean
            this.objectFlooringConcrete = data.objectFlooringConcrete as boolean
            this.objectFlooringEpoxyResinFloor = data.objectFlooringEpoxyResinFloor as boolean

            this.objectFlooringNotice = data.objectFlooringNotice as string

            this.objectHeatingOven = data.objectHeatingOven as boolean
            this.objectHeatingFloat = data.objectHeatingFloat as boolean
            this.objectHeatingCentral = data.objectHeatingCentral as boolean
            this.objectHeatingPump = data.objectHeatingPump as boolean
            this.objectHeatingPowerPlant = data.objectHeatingPowerPlant as boolean

            this.objectHeatingNotice = data.objectHeatingNotice as string

            this.objectInternetSpeed= data.objectInternetSpeed as number
            this.objectGardenType= data.objectGardenType as string
            this.objectGardenTypeNotice= data.objectGardenTypeNotice as string
        }
    }

    toData() {
        return {
            objectCurrentUsageType : this.objectCurrentUsageType,
            objectCurrentUsageNotice: this.objectCurrentUsageNotice,
            objectAvailableFrom: this.objectAvailableFrom,
            objectAvailableFromNotice: this.objectAvailableFromNotice,
            objectConstructionCompletionType: this.objectConstructionCompletionType,
            objectConstructionCompletionNotice: this.objectConstructionCompletionNotice,
            objectConstructionCompletionMonth: this.objectConstructionCompletionMonth,
            objectConstructionCompletionYear: this.objectConstructionCompletionYear,
            objectLastModernisation: this.objectLastModernisation,
            objectLastModernisationNotice: this.objectLastModernisationNotice,
            objectConditionType: this.objectConditionType,
            objectConditionTypeNotice: this.objectConditionTypeNotice,
            objectWindowType: this.objectWindowType,
            objectWindowTypeNotice: this.objectWindowTypeNotice,
            objectRoofType: this.objectRoofType,
            objectRoofTypeNotice: this.objectRoofTypeNotice,
            objectRoofShapeType: this.objectRoofShapeType,
            objectRoofShapeTypeNotice: this.objectRoofShapeTypeNotice,
            objectRoofCoverType: this.objectRoofCoverType,
            objectRoofCoverTypeNotice: this.objectRoofCoverTypeNotice,
            objectDesignType: this.objectDesignType,
            objectDesignTypeNotice: this.objectDesignTypeNotice,
            objectDevelopmentType: this.objectDevelopmentType,
            objectDevelopmentTypeNotice: this.objectDevelopmentTypeNotice,
            objectBasementType: this.objectBasementType,
            objectBasementTypeNotice: this.objectBasementTypeNotice,
            objectStories: this.objectStories,
            objectStoriesNotice: this.objectStoriesNotice,

            objectFlooringFloor: this.objectFlooringFloor,
            objectFlooringPVC: this.objectFlooringPVC,
            objectFlooringCarpet: this.objectFlooringCarpet,
            objectFlooringFlow: this.objectFlooringFlow,
            objectFlooringLaminate: this.objectFlooringLaminate,
            objectFlooringParquet: this.objectFlooringParquet,
            objectFlooringStone: this.objectFlooringStone,
            objectFlooringScreed: this.objectFlooringScreed,
            objectFlooringDoublefloor: this.objectFlooringDoublefloor,
            objectFlooringConcrete: this.objectFlooringConcrete,
            objectFlooringEpoxyResinFloor: this.objectFlooringEpoxyResinFloor,

            objectFlooringNotice: this.objectFlooringNotice,

            objectHeatingOven: this.objectHeatingOven,
            objectHeatingFloat: this.objectHeatingFloat,
            objectHeatingCentral: this.objectHeatingCentral,
            objectHeatingPump: this.objectHeatingPump,
            objectHeatingPowerPlant: this.objectHeatingPowerPlant,

            objectHeatingNotice: this.objectHeatingNotice,

            objectInternetSpeed: this.objectInternetSpeed,
            objectGardenType: this.objectGardenType,
            objectGardenTypeNotice: this.objectGardenTypeNotice
        }
    }
}

export class ObjectEstateFeatures {

    featuresStandardStorageRoom: boolean = false
    featuresStandardBathtub: boolean = false
    featuresStandardBalcony: boolean = false
    featuresStandardHandicapped: boolean = false
    featuresStandardMonumentProtection: boolean = false
    featuresStandardShower: boolean = false
    featuresStandardDVCabling: boolean = false
    featuresStandardGranningApartment: boolean = false
    featuresStandardInitial: boolean = false
    featuresStandardPassengerElevator: boolean = false
    featuresStandardSauna: boolean = false
    featuresStandardSeniorFriendly: boolean = false
    featuresStandardSwimmingPool: boolean = false
    featuresStandardDaylightBathroom: boolean = false
    featuresStandardTerrace: boolean = false
    featuresStandardUMTSReception: boolean = false
    featuresStandardWinterGarden: boolean = false
    featuresStandardNonSmoker: boolean = false
    featuresStandardSecondBathroom: boolean = false

    featuresCustomTilesSweedenOven: boolean = false
    featuresCustomCorkFloor: boolean = false

    featuresSpecial: string = ""
    featuresQualityType: string = ""
    featuresFurnitureType: string = ""
    featuresFurnitureTypeNotice: string = ""

    constructor(data: Record<string, unknown> | null = null) {
        if (data) {
            this.featuresStandardStorageRoom = data.featuresStandardStorageRoom as boolean
            this.featuresStandardBathtub = data.featuresStandardBathtub as boolean
            this.featuresStandardBalcony = data.featuresStandardBalcony as boolean
            this.featuresStandardHandicapped = data.featuresStandardHandicapped as boolean
            this.featuresStandardMonumentProtection = data.featuresStandardMonumentProtection as boolean
            this.featuresStandardShower = data.featuresStandardShower as boolean
            this.featuresStandardDVCabling = data.featuresStandardDVCabling as boolean
            this.featuresStandardGranningApartment = data.featuresStandardGranningApartment as boolean
            this.featuresStandardInitial = data.featuresStandardInitial as boolean
            this.featuresStandardPassengerElevator = data.featuresStandardPassengerElevator as boolean
            this.featuresStandardSauna = data.featuresStandardSauna as boolean
            this.featuresStandardSeniorFriendly = data.featuresStandardSeniorFriendly as boolean
            this.featuresStandardSwimmingPool = data.featuresStandardSwimmingPool as boolean
            this.featuresStandardDaylightBathroom = data.featuresStandardDaylightBathroom as boolean
            this.featuresStandardTerrace = data.featuresStandardTerrace as boolean
            this.featuresStandardUMTSReception = data.featuresStandardUMTSReception as boolean
            this.featuresStandardWinterGarden = data.featuresStandardWinterGarden as boolean
            this.featuresStandardNonSmoker = data.featuresStandardNonSmoker as boolean
            this.featuresStandardSecondBathroom = data.featuresStandardSecondBathroom as boolean

            this.featuresCustomTilesSweedenOven = data.featuresCustomTilesSweedenOven as boolean
            this.featuresCustomCorkFloor = data.featuresCustomCorkFloor as boolean

            this.featuresSpecial = data.featuresSpecial as string
            this.featuresQualityType = data.featuresQualityType as string
            this.featuresFurnitureType = data.featuresFurnitureType as string
            this.featuresFurnitureTypeNotice = data.featuresFurnitureTypeNotice as string
        }
    }

    toData() {
        return {
            featuresStandardStorageRoom: this.featuresStandardStorageRoom,
            featuresStandardBathtub: this.featuresStandardBathtub,
            featuresStandardBalcony: this.featuresStandardBalcony,
            featuresStandardHandicapped: this.featuresStandardHandicapped,
            featuresStandardMonumentProtection: this.featuresStandardMonumentProtection,
            featuresStandardShower: this.featuresStandardShower,
            featuresStandardDVCabling: this.featuresStandardDVCabling,
            featuresStandardGranningApartment: this.featuresStandardGranningApartment,
            featuresStandardInitial: this.featuresStandardInitial,
            featuresStandardPassengerElevator: this.featuresStandardPassengerElevator,
            featuresStandardSauna: this.featuresStandardSauna,
            featuresStandardSeniorFriendly: this.featuresStandardSeniorFriendly,
            featuresStandardSwimmingPool: this.featuresStandardSwimmingPool,
            featuresStandardDaylightBathroom: this.featuresStandardDaylightBathroom,
            featuresStandardTerrace: this.featuresStandardTerrace,
            featuresStandardUMTSReception: this.featuresStandardUMTSReception,
            featuresStandardWinterGarden: this.featuresStandardWinterGarden,
            featuresStandardNonSmoker: this.featuresStandardNonSmoker,
            featuresStandardSecondBathroom: this.featuresStandardSecondBathroom,

            featuresCustomTilesSweedenOven: this.featuresCustomTilesSweedenOven,
            featuresCustomCorkFloor: this.featuresCustomCorkFloor,

            featuresSpecial: this.featuresSpecial,
            featuresQualityType: this.featuresQualityType,
            featuresFurnitureType: this.featuresFurnitureType,
            featuresFurnitureTypeNotice: this.featuresFurnitureTypeNotice

        }
    }
}

export class ObjectEstateLandRegisterData {
    registerWENo: string = ""
    registerRentalOwnershipInterest: string | number = ""
    registerRentalOwnershipInterestBase: string | number = ""
    registerDeedOfDivisionNo: string = ""
    registerDistrictCourt: string = ""
    registerLandRegisterOf: string = ""
    registerDistrict: string = ""
    registerCorridor: string = ""
    registerParcel: string = ""
    registerVolumeSheetPage: string = ""
    registerDepartmentsItoIII: string = ""


    constructor(data: Record<string, unknown> | null = null) {
        if (data) {
            this.registerWENo = data.registerWENo as string
            this.registerRentalOwnershipInterest = data.registerRentalOwnershipInterest as number
            this.registerRentalOwnershipInterestBase = data.registerRentalOwnershipInterestBase as number
            this.registerDeedOfDivisionNo = data.registerDeedOfDivisionNo as string
            this.registerDistrictCourt = data.registerDistrictCourt as string
            this.registerLandRegisterOf = data.registerLandRegisterOf as string
            this.registerDistrict = data.registerDistrict as string
            this.registerCorridor = data.registerCorridor as string
            this.registerParcel = data.registerParcel as string
            this.registerVolumeSheetPage = data.registerVolumeSheetPage as string
            this.registerDepartmentsItoIII = data.registerDepartmentsItoIII as string
        }
    }

    toData() {
        return {
            registerWENo: this.registerWENo,
            registerRentalOwnershipInterest: this.registerRentalOwnershipInterest,
            registerRentalOwnershipInterestBase: this.registerRentalOwnershipInterestBase,
            registerDeedOfDivisionNo: this.registerDeedOfDivisionNo,
            registerDistrictCourt: this.registerDistrictCourt,
            registerLandRegisterOf: this.registerLandRegisterOf,
            registerDistrict: this.registerDistrict,
            registerCorridor: this.registerCorridor,
            registerParcel: this.registerParcel,
            registerVolumeSheetPage: this.registerVolumeSheetPage,
            registerDepartmentsItoIII: this.registerDepartmentsItoIII
        }
    }
}
export class ObjectEstateEnergy {
    energyStandardType: string = ""
    energyPassType: string = ""
    oel: boolean = false
    Gas: boolean = false
    Fernwaerme: boolean = false
    Elektroenergie: boolean = false
    AlternativeEnergietraeger: boolean = false
    Solarenergie: boolean = false
    Erdwaerme: boolean = false
    Kohle: boolean = false
    Pellets: boolean = false
    Holz: boolean = false
    Fluessiggas: boolean = false
    Erdgasleicht: boolean = false
    Erdgasschwer: boolean = false
    FernwaermeDampf: boolean = false
    HolzHackschnitzel: boolean = false
    Nahwaerme: boolean = false
    Bioenergie: boolean = false
    Windenergie: boolean = false
    Wasserenergie: boolean = false
    Umweltwaerme: boolean = false
    KWKFossil: boolean = false
    KWKErneuerbar: boolean = false
    KWKRegenerativ: boolean = false
    KWKBiologisch: boolean = false

    constructor(data: Record<string, unknown> | null = null) {
        if (data) {
            this.energyStandardType = data.energyStandardType as string
            this.energyPassType = data.energyPassType as string
            this.oel = data.oel as boolean
            this.Gas = data.Gas as boolean
            this.Fernwaerme = data.Fernwaerme as boolean
            this.Elektroenergie = data.Elektroenergie as boolean
            this.AlternativeEnergietraeger = data.AlternativeEnergietraeger as boolean
            this.Solarenergie = data.Solarenergie as boolean
            this.Erdwaerme = data.Erdwaerme as boolean
            this.Kohle = data.Kohle as boolean
            this.Pellets = data.Pellets as boolean
            this.Holz = data.Holz as boolean
            this.Fluessiggas = data.Fluessiggas as boolean
            this.Erdgasleicht = data.Erdgasleicht as boolean
            this.Erdgasschwer = data.Erdgasschwer as boolean
            this.FernwaermeDampf = data.FernwaermeDampf as boolean
            this.HolzHackschnitzel = data.HolzHackschnitzel as boolean
            this.Nahwaerme = data.Nahwaerme as boolean
            this.Bioenergie = data.Bioenergie as boolean
            this.Windenergie = data.Windenergie as boolean
            this.Wasserenergie = data.Wasserenergie as boolean
            this.Umweltwaerme = data.Umweltwaerme as boolean
            this.KWKFossil = data.KWKFossil as boolean
            this.KWKErneuerbar = data.KWKErneuerbar as boolean
            this.KWKRegenerativ = data.KWKRegenerativ as boolean
            this.KWKBiologisch = data.KWKBiologisch as boolean
            
        }
    }

    toData() {
        const data: Record<string, unknown> = {
            energyStandardType: this.energyStandardType,
            energyPassType: this.energyPassType,
            oel: this.oel,
            Gas: this.Gas,
            Fernwaerme: this.Fernwaerme,
            Elektroenergie: this.Elektroenergie,
            AlternativeEnergietraeger: this.AlternativeEnergietraeger,
            Solarenergie: this.Solarenergie,
            Erdwaerme: this.Erdwaerme,
            Kohle: this.Kohle,
            Pellets: this.Pellets,
            Holz: this.Holz,
            Fluessiggas: this.Fluessiggas,
            Erdgasleicht: this.Erdgasleicht,
            Erdgasschwer: this.Erdgasschwer,
            FernwaermeDampf: this.FernwaermeDampf,
            HolzHackschnitzel: this.HolzHackschnitzel,
            Nahwaerme: this.Nahwaerme,
            Bioenergie: this.Bioenergie,
            Windenergie: this.Windenergie,
            Wasserenergie: this.Wasserenergie,
            Umweltwaerme: this.Umweltwaerme,
            KWKFossil: this.KWKFossil,
            KWKErneuerbar: this.KWKErneuerbar,
            KWKRegenerativ: this.KWKRegenerativ,
            KWKBiologisch: this.KWKBiologisch
        }
        return data
    }
}
export class ObjectEstateExposure {
    exposeTitle: string = ""
    exposePrintRow: string = ""
    exposeShortDescription: string = ""
    exposeShortDescriptionShowOnline: boolean = false
    exposeObjectGeneralDescription: string = ""
    exposeObjectGeneralDescriptionShowOnline: boolean = false
    exposeFeaturesDescription: string = ""
    exposeFeaturesDescriptionShowOnline: boolean = false
    exposeLocationDescription: string = ""
    exposeLocationDescriptionShowOnline: boolean = false
    exposeOtherDescription: string = ""
    exposeOtherDescriptionShowOnline: boolean = false

    constructor(data: Record<string, unknown> | null = null) {
        if (data) {
            this.exposeTitle = data.exposeTitle as string
            this.exposePrintRow = data.exposePrintRow as string
            this.exposeShortDescription = data.exposeShortDescription as string
            this.exposeShortDescriptionShowOnline = data.exposeShortDescriptionShowOnline as boolean
            this.exposeObjectGeneralDescription = data.exposeObjectGeneralDescription as string
            this.exposeObjectGeneralDescriptionShowOnline = data.exposeObjectGeneralDescriptionShowOnline as boolean
            this.exposeFeaturesDescription = data.exposeFeaturesDescription as string
            this.exposeFeaturesDescriptionShowOnline = data.exposeFeaturesDescriptionShowOnline as boolean
            this.exposeLocationDescription = data.exposeLocationDescription as string
            this.exposeLocationDescriptionShowOnline = data.exposeLocationDescriptionShowOnline as boolean
            this.exposeOtherDescription = data.exposeOtherDescription as string
            this.exposeOtherDescriptionShowOnline = data.exposeOtherDescriptionShowOnline as boolean
        }
    }

    toData() {
        return {
            exposeTitle: this.exposeTitle,
            exposePrintRow: this.exposePrintRow,
            exposeShortDescription: this.exposeShortDescription,
            exposeShortDescriptionShowOnline: this.exposeShortDescriptionShowOnline,
            exposeObjectGeneralDescription: this.exposeObjectGeneralDescription,
            exposeObjectGeneralDescriptionShowOnline: this.exposeObjectGeneralDescriptionShowOnline,
            exposeFeaturesDescription: this.exposeFeaturesDescription,
            exposeFeaturesDescriptionShowOnline: this.exposeFeaturesDescriptionShowOnline,
            exposeLocationDescription: this.exposeLocationDescription,
            exposeLocationDescriptionShowOnline: this.exposeLocationDescriptionShowOnline,
            exposeOtherDescription: this.exposeOtherDescription,
            exposeOtherDescriptionShowOnline: this.exposeOtherDescriptionShowOnline
        }
    }
}
export class ObjectEstateDocumentTexts {
    documentTargetGroupType: string = ""
    documentHeadline: string = ""
    documentImageTitle: string = ""
    documentText: string = ""
    documentSellingPoint1: string = ""
    documentSellingPoint2: string = ""
    documentSellingPoint3: string = ""
    documentSellingPoint4: string = ""
    documentSellingPoint5: string = ""


    constructor(data: Record<string, unknown> | null = null) {
        if (data) {
            this.documentTargetGroupType = data.documentTargetGroupType as string
            this.documentHeadline = data.documentHeadline as string
            this.documentImageTitle = data.documentImageTitle as string
            this.documentText = data.documentText as string
            this.documentSellingPoint1 = data.documentSellingPoint1 as string
            this.documentSellingPoint2 = data.documentSellingPoint2 as string
            this.documentSellingPoint3 = data.documentSellingPoint3 as string
            this.documentSellingPoint4 = data.documentSellingPoint4 as string
            this.documentSellingPoint5 = data.documentSellingPoint5 as string
        }
    }

    toData() {
        return {
            documentTargetGroupType: this.documentTargetGroupType,
            documentHeadline: this.documentHeadline,
            documentImageTitle: this.documentImageTitle,
            documentText: this.documentText,
            documentSellingPoint1: this.documentSellingPoint1,
            documentSellingPoint2: this.documentSellingPoint2,
            documentSellingPoint3: this.documentSellingPoint3,
            documentSellingPoint4: this.documentSellingPoint4,
            documentSellingPoint5: this.documentSellingPoint5
        }
    }
}
export class ObjectEstateSpace {
    usableSpace: number | string = ""
    usableSpaceType: string = ""
    balconySpace: number | string = ""
    balconySpaceType: string = ""
    bathRooms: number | string = ""
    bedRooms: number | string = ""
    carports: number | string = ""
    garages: number | string = ""
    parkingSpaces: number | string = ""
    untergroundParkingSpaces: number | string = ""

    constructor(data: Record<string, unknown> | null = null) {
        if (data) {
            this.usableSpace = data.usableSpace as number
            this.usableSpaceType = data.usableSpaceType as string
            this.balconySpace = data.balconySpace as number
            this.balconySpaceType = data.balconySpaceType as string
            this.bathRooms = data.bathRooms as number
            this.bedRooms = data.bedRooms as number
            this.carports = data.carports as number
            this.garages = data.garages as number
            this.parkingSpaces = data.parkingSpaces as number
            this.untergroundParkingSpaces = data.untergroundParkingSpaces as number
        }
    }

    toData() {
        return {
            usableSpace: this.usableSpace,
            usableSpaceType: this.usableSpaceType,
            balconySpace: this.balconySpace,
            balconySpaceType: this.balconySpaceType,
            bathRooms: this.bathRooms,
            bedRooms: this.bedRooms,
            carports: this.carports,
            garages: this.garages,
            parkingSpaces: this.parkingSpaces,
            untergroundParkingSpaces: this.untergroundParkingSpaces
        }
    }
}

export class ObjectEstateIncomeOutcome {
    rentIncomeActual: number | string = ""
    rentIncomeSetpoint: number | string = ""
    operatingCost: number | string = ""
    incomeOutcomeNotice: string = ""

    constructor(data: Record<string, unknown> | null = null) {
        if (data) {
            this.rentIncomeActual = data.rentIncomeActual as number
            this.rentIncomeSetpoint = data.rentIncomeSetpoint as number
            this.operatingCost = data.operatingCost as number
            this.incomeOutcomeNotice = data.incomeOutcomeNotice as string
        }
    }

    toData() {
        return {
            rentIncomeActual: this.rentIncomeActual,
            rentIncomeSetpoint: this.rentIncomeSetpoint,
            operatingCost: this.operatingCost,
            incomeOutcomeNotice: this.incomeOutcomeNotice
        }
    }
}

export type ObjectEstateImageFile = {
    id: string;
    storageUrl: string;
    localFile?: File;
    markedForDeletion?: boolean;
    filename: string;
    title: string;
    description: string;
};

export type ObjectEstateDocumentFile = {
    id: string;
    storageUrl: string;
    localFile?: File
    filename: string;
    title: string;
    description: string;
};

export class ObjectEstateFiles {
    imageFiles: ObjectEstateImageFile[] = []
    documentFiles: string[] = []
    

    constructor(data: Record<string, unknown> | null = null) {
        if (data) {
            console.log("CONSTRUCTOR",data.imageFiles)
            this.imageFiles = data.imageFiles as ObjectEstateImageFile[]
            this.documentFiles = data.documentFiles as string[]
        }
    }

    toData() {
        const data: Record<string, unknown> = {}
      
        data.imageFiles = this.imageFiles,
        data.documentFiles = this.documentFiles
        console.log("TODATA",this.imageFiles)
        console.log("TODATA",data)
        return data
    }
}

export class ObjectEstateInterested {
    id: string = ""
    timestamp: Date = new Date()
    rating: number = 0
    note: string | null = null
    flags: string[] = []

    constructor(data: Record<string, unknown> | null = null) {
        if (data) {
            this.id = data.id as string
            this.timestamp = (data.timestamp as Timestamp).toDate()
            this.rating = data.rating as number || 0
            this.note = data.note as string || null
            this.flags = data.flags as string[] || []
        }
    }
    addFlag(flag: string) {
        if (!this.hasFlag(flag)) {
            this.flags.push(flag)
        }
    }
    hasFlag(flag: string) {
        return this.flags.indexOf(flag) != -1
    }
    deleteFlag(flag: string) {
        if (this.hasFlag(flag)) {
            this.flags.splice(this.flags.indexOf(flag), 1)
        }
    }

    toData() {
        return {
            id: this.id,
            timestamp: this.timestamp,
            rating: this.rating,
            note: this.note,
            flags: this.flags
        }
    }
}

export class ObjectEstate extends FirestoreClass {
    private _company: Company

    public static ObjectEstateSubTypeItems: Map<number, ItemObject[]> = new Map([
        //Zimmer
        [
            1, [
                {
                    text: "Bitte wählen",
                    value: 0
                },
                {
                    text: "Gästezimmer",
                    value: 1
                },
                {
                    text: "WG- Zimmer",
                    value: 2
                },
                {
                    text: "Sonstiges",
                    value: 3
                }
            ]
        ],
        //Wohnung
        [
            2, [
                {
                    text: "Bitte wählen" ,
                    value: 0
                },
                {
                    text: "Apartment",
                    value: 1
                },
                {
                    text: "Attikawohnung",
                    value: 2
                },
                {
                    text: "Dachgeschosswohnung",
                    value: 3
                },
                {
                    text: "Erdgeschosswohnung",
                    value: 4
                },
                {
                    text: "Etagenwohnung",
                    value: 5
                },
                {
                    text: "Ferienwohnung",
                    value: 6
                },
                {
                    text: "Galeriewohnung",
                    value: 7
                },
                {
                    text: "Gartenwohnung",
                    value: 8
                },
                {
                    text: "Loft/Studio/Atelier",
                    value: 9
                },
                {
                    text: "Maisonette",
                    value: 10
                },
                {
                    text: "Pentouse",
                    value: 11
                },
                {
                    text: "Rohdachwohnung",
                    value: 12
                },
                {
                    text: "sonstiges",
                    value: 13
                },
                {
                    text: "Souterrainwohnung",
                    value: 14
                },
                {
                    text: "Terassenwohnung",
                    value: 15
                }
            ]
        ],
        //Einfamilienhaus
        [
            3,[
                {
                    text: "Bitte wählen",
                    value: 0
                },
                {
                    text: "Bauernhaus/Hof",
                    value: 1
                },
                {
                    text: "Berg-/Skihütte",
                    value: 2
                },
                {
                    text: "Bungalow",
                    value: 3
                },
                {
                    text: "Burg/Schloss",
                    value: 4
                },
                {
                    text: "Chalet, Doppelhaus",
                    value: 5
                },
                {
                    text: "Doppelhaushälfte",
                    value: 6
                },
                {
                    text: "Einfamilienhaus",
                    value: 7
                },
                {
                    text: "Ferienhaus",
                    value: 8
                },
                {
                    text: "Fertighaus" ,
                    value: 9
                },
                {
                    text: "Finca",
                    value: 10
                },
                {
                    text: "Herenhaus",
                    value: 11
                },
                {
                    text: "Landhaus",
                    value: 12
                },
                {
                    text: "Reiheneckhaus",
                    value: 13
                },
                {
                    text: "Reihenendhaus",
                    value: 14
                },
                {
                    text: "Reihenmittelshaus",
                    value: 15
                },
                {
                    text: "Resthof",
                    value: 16
                },
                {
                    text: "Rustico",
                    value: 17
                },
                {
                    text: "Sonstiges",
                    value: 18
                },
                {
                    text: "Stadthaus",
                    value: 19
                },
                {
                    text: "Strandhaus",
                    value: 20
                },
                {
                    text: "Villa",
                    value: 21
                },
                {
                    text: "Wochenendhaus",
                    value: 22
                },
                {
                    text: "Zweifamilienhaus",
                    value: 23
                }
            ]
        ],
        //Wohn/Geschäftshaus
        [
            4,[
                {
                    text: "Bitte wählen",
                    value: 0
                },
                {
                    text: "Arpartmenthaus",
                    value: 1
                },
                {
                    text: "Ärzte-/Büro-/Geschäftshaus",
                    value: 2
                },
                {
                    text: "betreutes Wohnen",
                    value: 3
                },
                {
                    text: "Mehrfamilienhaus",
                    value: 4
                },
                {
                    text: "Mehrfamilienhaus mit Gewerbe",
                    value: 5
                },
                {
                    text: "Pflegeheim",
                    value: 6
                },
                {
                    text: "Sanatorium",
                    value: 7
                },
                {
                    text: "Seniorenheim",
                    value: 8
                },
                {
                    text: "sonstiges",
                    value: 9
                },
                {
                    text: "Wohnanlage",
                    value: 10
                }
            ]
        ],
        //Grundstück
        [
            5, [
                {
                    text: "Bitte wählen",
                    value: 0
                },
                {
                    text: "Bauerwartungsland",
                    value: 1
                },
                {
                    text: "Freizeitgrundstück",
                    value: 2
                },
                {
                    text: "Gemischte Nutzung",
                    value: 3
                },
                {
                    text: "Gewerbegrundstück",
                    value: 4
                },
                {
                    text: "Land-/Forstwirtschaft",
                    value: 5
                },
                {
                    text: "Seeliegenschaft",
                    value: 6
                },
                {
                    text: "sonstiges",
                    value: 7
                },
                {
                    text: "Wohnbaugrundstück",
                    value: 8
                }
            ]
        ],
        //Gewerbeobjekt
        [
            6, [
                {
                    text: "Bitte wählen",
                    value: 0
                },
                {
                    text: "Ausstellungsfläche",
                    value: 1
                },
                {
                    text: "Büro/Praxis",
                    value: 2
                },
                {
                    text: "Dienstleistungsbetrieb",
                    value: 3
                },
                {
                    text: "Einkaufszentrum",
                    value: 4
                },
                {
                    text: "Freifläche",
                    value: 5
                },
                {
                    text: "Freizeit-/Sportanlage",
                    value: 6
                },
                {
                    text: "Gastronomie",
                    value: 7
                },
                {
                    text: "Gesundheit/Soziales",
                    value: 8
                },
                {
                    text: "Halle",
                    value: 9
                },
                {
                    text: "Hotel",
                    value: 10
                },
                {
                    text: "Ladenlokal",
                    value: 11
                },
                {
                    text: "Lager",
                    value: 12
                },
                {
                    text: " Parkhaus/Garage",
                    value: 13
                },
                {
                    text: "Pension",
                    value: 14
                },
                {
                    text: "Produktionsbetrieb",
                    value: 15
                },
                {
                    text: "Spezialimmobilie/Sonstiges",
                    value: 16
                },
                {
                    text: "Verbrauchermarkt",
                    value: 17
                },
                {
                    text: "Werkstatt/Handwerksbetrieb",
                    value: 18
                }
            ]
        ],
        //Landwirtschaftliches Objekt
        [
            7, [
                {
                    text: "Bitte wählen",
                    value: 0
                },
                {
                    text: "Ackerbau",
                    value: 1
                },
                {
                    text: "Fisch-/Teichwirtschaft",
                    value: 2
                },
                {
                    text: "Gartenbau",
                    value: 3
                },
                {
                    text: "Jagt-/Forstwirtschaft",
                    value: 4
                },
                {
                    text: "landwirtschaflicher Betrieb/Hof",
                    value: 5
                },
                {
                    text: "Reiterhof",
                    value: 6
                },
                {
                    text: "sonstiges",
                    value: 7
                },
                {
                    text: "Viehwirtschaft",
                    value: 8
                },
                {
                    text: "Weingut",
                    value: 9
                }
            ]
        ],
        //Garage/Stellplatz
        [
            8, [
                {
                    text: "Bitte wählen",
                    value: 0
                },
                {
                    text: "Bootsliegeplatz",
                    value: 1
                },
                {
                    text: "Carport",
                    value: 2
                },
                {
                    text: "Doppelgarage",
                    value: 3
                },
                {
                    text: "Einzelgarage",
                    value: 4
                },
                {
                    text: "oberirdischer Stellplatz",
                    value: 5
                },
                {
                    text: "Stellplatz mit Ladeanschluss",
                    value: 6
                },
                {
                    text: "TG-Duplexstellplatz",
                    value: 7
                },
                {
                    text: "TG-Einzelstellplatz",
                    value: 8
                }
            ]
        ]
    ])

    public static ObjectEstateMainTypeItems: ItemObject[] = [
        {
            text: "Bitte wählen",
            value: 0
        },
        {
            text: "Zimmer",
            value: "room"
        },
        {
            text: "Wohnung",
            value: 2
        },
        {
            text: "Ein-/Zweifamilienhaus",
            value: 3
        },
        {
            text: "Wohn/Geschäftshaus",
            value: 4
        },
        {
            text: "Grundstück",
            value: 5
        },
        {
            text: "Gewerbeobjekt",
            value: 6
        },
        {
            text: "Landwirtschaftliches Objekt",
            value: 7
        },
        {
            text: "Garage/Stellplatz",
            value: 8
        }
    ]


    interested: ObjectEstateInterested[] = []
    agent: string = ""

    // OBJEKTUEBERSICHT
    // Typ
    mainType: string = ""
    subType: string = ""
    // Lage
    address: Address = new Address()

    // ANBIETER/KOSTEN
    // Vertriebsauftrag/Anbieter
    provider: ObjectEstateProvider = new ObjectEstateProvider()
    // Kosten
    cost: ObjectEstateCost = new ObjectEstateCost()

    // WOHN-/NUTZFLAECHEN
    // Nutzung
    usage: ObjectEstateUsage = new ObjectEstateUsage()
    // Einnahmen/ Ausgaben
    incomeOutcome: ObjectEstateIncomeOutcome = new ObjectEstateIncomeOutcome()
    // Flaechen/ Zimmer
    space: ObjectEstateSpace = new ObjectEstateSpace()

    // OBJEKTDETAILS
    // Objektdetails
    details: ObjectEstateDetails = new ObjectEstateDetails()
    // Ausstattung
    features: ObjectEstateFeatures = new ObjectEstateFeatures()

    // GRUNDBUCHANGABEN
    // Grundbuchangaben
    registerData: ObjectEstateLandRegisterData = new ObjectEstateLandRegisterData()
    // Energie
    energy: ObjectEstateEnergy = new ObjectEstateEnergy()

    // EXPOSEDETAILS
    // Expose
    files: ObjectEstateFiles = new ObjectEstateFiles()
    // Expose
    exposure: ObjectEstateExposure = new ObjectEstateExposure()
    // Dokumenttexte
    documentTexts: ObjectEstateDocumentTexts = new ObjectEstateDocumentTexts()

    constructor(company: Company, id: string = "") {
        super(id)
        this._company = company
    }

    addInterested(interested: Interested) {
        const objectInterested = new ObjectEstateInterested()
        objectInterested.id = interested.id
        objectInterested.timestamp = new Date()
        for (const i of this.interested) {
            if (i.id == objectInterested.id) {
                return
            }
        }
        this.interested.push(objectInterested)
    }

    override toData() {
        const data: Record<string, Partial<unknown>> = {}
        data.provider = this.provider.toData()

        data.agent = this.agent
        const _i = []
        for (const interested of this.interested) {
            _i.push(interested.toData())
        }
        data.interested = _i
        data._searchIndex = [
            this.address.street.toUpperCase(),
            this.address.postcode.toUpperCase(),
            this.address.location.toUpperCase()
        ]

        // OBJEKTUEBERSICHT
        // Typ
        data.mainType = this.mainType
        data.subType = this.subType
        // Lage
        data.address = this.address.toData()

        // ANBIETER/KOSTEN
        // Vertriebsauftrag/Anbieter
        data.provider = this.provider.toData()
        // Kosten
        data.cost = this.cost.toData()

        // WOHN-/NUTZFLAECHEN
        // Nutzung
        data.usage = this.usage.toData()
        // Einnahmen/ Ausgaben
        data.incomeOutcome = this.incomeOutcome.toData()
        // Flaechen/ Zimmer
        data.space = this.space.toData()

        // OBJEKTDETAILS
        // Objektdetails
        data.details = this.details.toData()
        // Ausstattung
        data.features = this.features.toData()

        // GRUNBUCHANGABEN
        // Grundbuchangaben
        data.registerData = this.registerData.toData()
        // Energie
        data.energy = this.energy.toData()

        // EXPOSEDETAILS
        // Files
        data.files = this.files.toData()
        // Expose
        data.exposure = this.exposure.toData()
        // Dokumenttexte
        data.documentTexts = this.documentTexts.toData()
        return data
    }
    override fromData(data: Record<string, unknown>): void {
        this.agent = data.agent as string
        if (data.provider) {
            this.provider = new ObjectEstateProvider(data.provider as Record<string, unknown>)
        }
        if (data.interested) {
            for (const interested of data.interested as Record<string, unknown>[]) {
                this.interested.push(new ObjectEstateInterested(interested))
            }
        }

        // OBJEKTUEBERSICHT
        // Typ
        if (data.mainType){
            this.mainType = data.mainType as string
        }
        if (data.subType){
            this.subType = data.subType as string
        }
        // Lage
        if (data.address) {
            this.address = new Address(data.address as Record<string, unknown>)
        }

        // ANBIETER/KOSTEN
        // Vertriebsauftrag/Anbieter
        if (data.provider) {
            this.provider = new ObjectEstateProvider(data.provider as Record<string, unknown>)
        }
        // Kosten
        if (data.cost) {
            this.cost = new ObjectEstateCost(data.cost as Record<string, unknown>)
        }

        // WOHN-/NUTZFLAECHEN
        // Nutzung
        if (data.usage) {
            this.usage = new ObjectEstateUsage(data.usage as Record<string, unknown>)
        }
        // Einnahmen/ Ausgaben
        if (data.incomeOutcome) {
            this.incomeOutcome = new ObjectEstateIncomeOutcome(data.incomeOutcome as Record<string, unknown>)
        }
        // Flaechen/ Zimmer
        if (data.space) {
            this.space = new ObjectEstateSpace(data.space as Record<string, unknown>)
        }

        // OBJEKTDETAILS
        // Objektdetails
        if (data.details) {
            this.details = new ObjectEstateDetails(data.details as Record<string, unknown>)
        }
        // Ausstattung
        if (data.features) {
            this.features = new ObjectEstateFeatures(data.features as Record<string, unknown>)
        }

        // GRUNBUCHANGABEN
        // Grundbuchangaben
        if (data.registerData) {
            this.registerData = new ObjectEstateLandRegisterData(data.registerData as Record<string, unknown>)
        }
        // Energie
        if (data.energy) {
            this.energy = new ObjectEstateEnergy(data.energy as Record<string, unknown>)
        }


        // EXPOSEDETAILS
        // Files
        if (data.files) {
            this.files = new ObjectEstateFiles(data.files as Record<string, unknown>)
        }
        // Expose
        if (data.exposure) {
            this.exposure = new ObjectEstateExposure(data.exposure as Record<string, unknown>)
        }
        // Dokumenttexte
        if (data.documentTexts) {
            this.documentTexts = new ObjectEstateDocumentTexts(data.documentTexts as Record<string, unknown>)
        }
    }

    override get ref() {
        return doc(this.collectionRef, this.id)
    }
    override get collectionRef() {
        return collection(this._company.ref, "object")
    }

    get company() {
        return this._company
    }
}
