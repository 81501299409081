import { initializeApp } from "firebase/app"
import { getFirestore, connectFirestoreEmulator } from "firebase/firestore"
import { getAuth, connectAuthEmulator, onAuthStateChanged, getIdTokenResult } from "firebase/auth"
import { getFunctions, connectFunctionsEmulator } from "firebase/functions"
import { getStorage, connectStorageEmulator } from "firebase/storage"
import store from "./store"

const app = initializeApp({
    apiKey: process.env.VUE_APP_API_KEY,
    authDomain: process.env.VUE_APP_AUTH_DOMAIN,
    projectId: process.env.VUE_APP_PROJECT_ID,
    storageBucket: process.env.VUE_APP_STORAGE_BUCKET,
    messagingSenderId: process.env.VUE_APP_MESSAGING_SENDER_ID,
    appId: process.env.VUE_APP_APP_ID
})

const firestore = getFirestore(app)
const auth = getAuth(app)
const functions = getFunctions(app, "europe-west3")
const storage = getStorage(app)

if (location.hostname == "localhost") {
    connectFirestoreEmulator(firestore, "localhost", 8081)
    connectFunctionsEmulator(functions, "localhost", 5001)
    // connectAuthEmulator(auth, "http://localhost:9099")
    connectStorageEmulator(storage, "localhost", 9199)
}

onAuthStateChanged(auth, async (user) => {
    
    store.state.auth!.isAuthenticating = true
    store.state.auth!.isSignedIn = user != null
    if (user != null) {
        const claims = await getIdTokenResult(user, true)
        store.commit("setAuthClaims", {
            user,
            claims: claims.claims
        })
        await store.dispatch("attachCompany")
    } else {
        store.commit("setAuthClaims", {
            user: null,
            claims: null
        })
    }
    store.state.auth!.isAuthenticating = false
    
})

export {
    firestore,
    auth,
    functions,
    storage
}