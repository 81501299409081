import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router"


const routes: Array<RouteRecordRaw> = [
    {
        path: "/",
        name: "home",
        component: () => import(/* webpackChunkName: "home" */ "../views/home-view.vue")
    },
    {
        path: "/user-profile",
        name: "user-profile",
        component: () => import(/* webpackChunkName: "user-profile" */ "../views/user-profile-view.vue")
    },
    {
        path: "/company-profile",
        name: "company-profile",
        component: () => import(/* webpackChunkName: "company-profile" */ "../views/company-profile-view.vue")
    },
    {
        path: "/login",
        name: "login",
        component: () => import(/* webpackChunkName: "login" */ "../views/login-view.vue")
    },
    {
        path: "/objects",
        name: "objects",
        component: () => import(/* webpackChunkName: "objects" */ "../views/objects-view.vue")
    },
    {
        path: "/object/:objectID",
        name: "object",
        component: () => import(/* webpackChunkName: "object" */ "../views/object-view.vue")
    },
    {
        path: "/customers",
        name: "customers-list",
        component: () => import(/* webpackChunkName: "customers-list" */ "../views/customers-list-view.vue")
    },
    {
        path: "/customer/:customerid",
        name: "customer",
        component: () => import(/* webpackChunkName: "customer" */ "../views/customer-view.vue")
    },
    {
        path: "/interested",
        name: "interested-list",
        component: () => import(/* webpackChunkName: "interested-list" */ "../views/interested-list-view.vue")
    },
    {
        path: "/interested/:interestedID",
        name: "interested",
        component: () => import(/* webpackChunkName: "interested" */ "../views/interested-view.vue")
    },
    {
        path: "/calendar",
        name: "calendar",
        component: () => import(/* webpackChunkName: "calendar" */ "../views/calendar-view.vue")
    },
    
    {
        path: "/error",
        name: "error",
        component: () => import(/* webpackChunkName: "error" */ "../views/error-view.vue")
    },
    {
        path: "/api_callback_immoscout",
        name: "api-callback-immoscout",
        component: () => import(/* webpackChunkName: "api-callback-immoscout" */ "../views/api-callback-immoscout.vue")
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router
